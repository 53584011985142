import React from 'react';
import Analytics from '../components/analytics';
import Layout from '../components/layout';
import Main from '../components/main';

const Index = () => {
  return (
    <Layout>
      <Analytics pageUrl="/" />
      <Main />
    </Layout>
  );
}

export default Index;
