import React, { useState } from 'react';

import Loader from './loader';
import QuestionOne from './Questions/QuestionOne';
import QuestionTwo from './Questions/QuestionTwo';
import Header from './header';
import cms from '../cms.json';
import background from '../images/background.jpg';

const Main = () => {
  const [loaded, setLoaded] = useState(false);
  const [step, setStep] = useState(1);

  const nextStep = () => {
    setStep(2);
  }

  return (
    <React.Fragment>
      <div className="dead-space" />
      {!loaded && (
        <Loader
          setLoaded={setLoaded}
        />
      )}
      {loaded && (
        <div className="content">
          <Header white="false" />
          <img className="content__background" src={background} alt=""/>
          {step === 1 && <QuestionOne cms={cms.questionOne} nextStep={nextStep} />}
          {step === 2 && <QuestionTwo cms={cms.questionTwo} />}
        </div>
      )}
    </React.Fragment>
  );
};

export default Main;
