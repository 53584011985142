import React, { useState, useEffect } from 'react';
import { TweenMax, Sine } from 'gsap';
import { Link } from "gatsby"

import styles from '../../scss/components/question.module.scss';

const QuestionTwo = ({ cms }) => {
  const [answerTwo, setAnswerTwo] = useState(null);

  const answerSelected = e => {
    const buttons = document.querySelectorAll('.button--question');
    setAnswerTwo(e.target.dataset.value);
    localStorage.setItem('answerTwo', e.target.dataset.value);
    const element = e.currentTarget;

    buttons.forEach(button => {
      button.classList.remove('active');
    });

    element.classList.add('active');
  };

  useEffect(() => {
    TweenMax.to('.question', 0.5, {
      ease: Sine.easeInOut,
      opacity: 1,
      delay: 0.5,
    });
  }, []);

  return (
    <div className={`${styles.question} question flex-center`}>
      <h2>{cms.eyebrow}</h2>
      <h1 className={styles.question__title}>{cms.title}</h1>
       <ul className={styles.question__buttons}>
        {cms.answers.map((value, index) => {
          return <li key={index}>
            <button onClick={answerSelected} data-event-label={`selected ${value} for question two`} data-value={index + 1} className="button button--question">{value}</button>
          </li>
        })}
      </ul>
      <Link to="/payoff" data-event-label={cms.button} className={`button ${!answerTwo ? 'disabled' : ''}`}>{cms.button}</Link>
    </div>
  );
};

export default QuestionTwo;
