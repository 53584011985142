import React, { useEffect } from 'react';
import { TimelineMax, Sine } from 'gsap';
import Svg from 'react-inlinesvg';
import logo from '../images/svgs/logo.svg';
import loader from '../images/loader.png';

import styles from '../scss/components/loader.module.scss';

const Loader = ({ setLoaded }) => {
  useEffect(() => {
    const tl = new TimelineMax({ onComplete: () => setLoaded(true) });
    tl.to('.loader', 0.5, {
      ease: Sine.easeInOut,
      opacity: 0,
      delay: 1.5,
    });
  }, []);

  return (
    <div className={`${styles.loader} loader`}>
      <div className={styles.loader__wrapper}>
        <img src={loader} className={styles.loader__wheel} alt=""/>
        <div className={styles.loader__text}>
          <Svg src={logo} className={styles.loader__logo} />
          <p>Something about you</p>
        </div>
      </div>
    </div>
  );
};

export default Loader;
