import React, { useState, useEffect } from 'react';
import { TweenMax, Sine } from 'gsap';

import styles from '../../scss/components/question.module.scss';

const QuestionOne = ({ cms, nextStep }) => {
  const [answerOne, setAnswerOne] = useState(null);

  const answerSelected = e => {
    const buttons = document.querySelectorAll('.button--question');
    setAnswerOne(e.target.dataset.value);
    localStorage.setItem('answerOne', e.target.dataset.value);
    const element = e.currentTarget;

    buttons.forEach(button => {
      button.classList.remove('active');
    });

    element.classList.add('active');
  };

  useEffect(() => {
    TweenMax.to('.question', 0.5, {
      ease: Sine.easeInOut,
      opacity: 1,
      delay: 0.5,
    });
  }, []);

  return (
    <div className={`${styles.question} question flex-center`}>
      <h2>{cms.eyebrow}</h2>
      <h1 className={styles.question__title}>{cms.title}</h1>
       <ul className={styles.question__buttons}>
        {cms.answers.map((value, index) => {
          return <li key={index}>
            <button onClick={answerSelected} data-event-label={`selected ${value} for question one`} data-value={index + 1} className="button button--question">{value}</button>
          </li>
        })}
      </ul>
      <button onClick={nextStep} data-event-label={cms.button} className={`button ${!answerOne ? 'disabled' : ''}`}>{cms.button}</button>
    </div>
  );
};

export default QuestionOne;
